// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appRoot:"https://apim-na.qa.mypepsico.com/it/dps/v1/cicdmaturitycaf",
  otdChartFeatureURL : "https://dev.azure.com/PepsiCoIT/Global_Data_Project/_workitems/edit/",
  authorization: {
    ALLOW_SSO: false,
    CLIENT_ID: 'dummy_client_id',
    AUTHORIZATION_URL: 'dummy_oauth_url',
  },
  config:{
    appBaseHref: '/',
    issuer: 'https://secure.ite.pepsico.com/oauth2/default',
    clientId:'0oa1rjp2jjbMUkFAn0h8',
    USE_INTERACTION_CODE:true,
    redirectUri: '/login/callback',
    scopes: ['openid', 'profile', 'email', 'offline_access'],
    pkce: true,
    testing: {
      disableHttpsCheck: true
    },
    tokenManager: {
      autoRenew: true,
      autoRemove: false
    },
  },
  execDashboardURL:"https://execdashboard.qa.intra.pepsico.com",
  fullStoryOrgId:"",
  hideSEMetricsColumn : "",
  cacheTTL: 5, // APIs CACHE EXPIRY TIME IN MINUTES, PLEASE MAKE THIS false IF YOU WANT TO DISABLE APIs CACHE
  commercialAndConsumerProgramList : [
    'B2B', 
    'GNRM', 
    'Guided Selling',
    'Snacks2U',
    'Retail360',
    'SalesPro Plus',
    'SalesLead Plus',
    'SalesHub Plus',
    'Snacks.com',
    'Journey Optimizer',
    'GPS',
    'Retail 360',
    'Canvas',
    'Global Producer Service',
    'test',
    'DTC Admin',
    'DTC Rewards',
    'DTC Loyalty',
    'DTC PTR Runtime',
    'DTC MX',
    'B2B APP',
    'B2B BFF',
    'B2B OCH',
    'B2B CEP',
    'AFH Digital Menu Pro',
    'Global Payment Gateway',
  ],
  supplyChainProgramList : [
    'DTV', 
    'Virtual Control Room', 
    'Walmart DSD',
    'MFGPro Plus'
  ],
  DSX_PROGRAMS : [
    'Engineering Maturity Dashboard',
    'CCT',
    'Admin Portal',
    'AppRuntime',
    'First Mile Customer Support',
    'Global Design System',
    'Receipt Scanning',
    'Notifications Framework',
    'Product Success Dashboard',
    ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
